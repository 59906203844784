import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx"
import Listing from "./Listing";
import Cover from "./Cover";
import PostTags from "../PostTags/PostTags";
import "./PostListing.scss";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        body: postEdge.node.body,
        hasExcerpt: postEdge.node.hasExcerpt
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();

    return (
      <div>
        { // Your post list here.
        postList.map(post => (
          <article className="single-post">
            <Cover src={post.cover} title={post.title} />
            <div className="post-date">{post.date}</div>
            <div className="post-title">
              <h1>
                <Link to={post.path} key={post.title}>
                  {post.title}
                </Link>
              </h1>
            </div>
            <PostTags tags={post.tags} />
            <MDXRenderer>{post.body}</MDXRenderer>
          </article>
        ))
        }
      </div>
    );
  }
}

export default PostListing;
